/*Ajout ANP */
.overlayText {
    position:absolute;
     top:30%;
     left:20%;
     z-index:1;
   }
   
   #topText {
     color: white;
     font-size: 20px;
     align-self: center;
   }
   
   .navbar-nav .nav-link
   {
       font-size: .85rem !important;
       @media (max-width: 1080px){
            font-size: .75rem !important;
       }
   }
   
   @media (min-width: 1081px){
       .navbar-expand-lg .navbar-nav .nav-link {
           padding-left: 1.3rem !important;
       }
   }
    @media (max-width: 1080px){
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }
   
   .bg-Apropo{
       background: url(../../media/images/video-institutionnelle.png) no-repeat 100% 50% #f6fbfb;
   }
   
   .btn-right{
       
   text-align : right;
   }
   
   /* video  */ 
   .overlay {
       position:absolute;
       top:25%;
       left:3%;
       z-index:1;
       text-align: center;
   }
   .overlay h2 {
       color:#ffffff;
       font-weight: 400 !important;
       margin-top: 1.5rem;
       
   }
   .overlay p
   {
       color: #fff;
       text-align: justify;
       margin-left: 20%;
       margin-right: 20%;
       margin-top: 1.4rem;
   }
   .banner-home:before {
    /* background: linear-gradient(90deg, rgba(6, 125, 188, 0.88) 0, rgba(6, 125, 188, 0.66) 50.22%, rgba(6, 125, 188, 0.33) 100%) !important;*/
   background: linear-gradient(90deg, rgba(56, 70, 75, 0.88) 0, rgba(56, 70, 75, 0.66) 50.22%, rgba(56, 70, 75, 0.33) 100%) !important;
}



.table.table-arrivees {
    margin-top: 0 !important;
}
.btn {
    font-weight: 400 !important;
}
.actu-resume{
    padding-top: 10px;
}
.date-actu{
    padding-top: 10px;
}
.titre-actu{
    font-weight: 500 !important;
}
.c-actualite .icon-newspaper{
    right: unset !important;
    left:5%;
}

.c-appels-offres .icon-appel{
    right: unset !important;
    left:5%;
}


/*Menu gauche*/

#sidebar {
    background-color: #ffffff !important;
}
/*#sidebar .nav .nav-item{
	margin: unset !important;
}*/

#sidebar .nav .nav-item a {

    background-color: #0184ba !important;
    border-radius: unset !important;
}
#sidebar .nav .nav-item a.active, #sidebar .nav .nav-item a:hover, #sidebar .nav .nav-item a:active{
    background-color: #00d6ff !important;/* color active 2 #3db0d6 */
}
/*Actualité home*/

.block-actu h3, .block-appels h3, .rounded-block h3
{
	color: #0084ba !important;
}
.block-actu p, .block-appels p, .rounded-block p
{
	font-size: 0.94rem !important;
	font-weight: unset !important;
    padding-top: 1.2rem !important;
}
.block-actu .date-actu, .block-appels .date-actu, .rounded-block .date-actu
{
	font-weight: 500 !important;
	
}
.block-actu, .block-appels, .rounded-block
{
	    padding: 1.2rem !important;
}
/*------------*/
.questionFaq {
    background-color: #0184ba !important;
    }

.play-btn2 {
    width: 5rem;
    height: 5rem;
    background: radial-gradient(white 60%,#fff 62%);
    border-radius: 50%;
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 0 20px rgb(255, 255, 255 / 50%);
    margin-left: -2.5rem;
}
.play-btn2::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #0084ba;
    z-index: 100;
    transition: all .4s cubic-bezier(.55,.055,.675,.19);
}

section.map h2
{
    padding-top: 18%;
}

select.form-control , input.form-control{
    padding-left: 10px !important;
    color: #000000  !important;
}

.block-actu p, .block-appels p, .rounded-block p
{
    padding-bottom: 1rem;
    padding-top: 0.5rem;
}
.not-home-page h3{
    text-transform: none  !important;
    margin: 1rem 0 .75rem !important;
    font-weight: 500 !important;
}
.date-appels-details{
font-weight: 600;
    color: grey;
    margin-top: 0!important;
    font-size: .8rem;
    margin-bottom: 0.5rem;
}

.slickappel .block-appels .date-appels{
    margin: 1rem 0 .75rem !important;
}

/*  Grands chantiers */

.not-home-page h2{
	text-transform: none !important;
}
/*@media only screen and (max-width: 1920px) {
.banner-home iframe {
	height: 1080px !important;
}
}*/
@media only screen and (max-width:1366 ) {
.banner-home iframe {
	height: 768px !important;
}
}

@media only screen and (max-width: 480px) {
.banner-home iframe {
	height: 270px !important;
}
.overlay p
{
	display: none;
}
.banner-home{
	height: 270px !important;

}
.play-btn2 {
    margin-top: 20px;
    width: 2.5rem;
    height: 2.5rem;
    background: radial-gradient(white 50%,#fff 60%);
    border-radius: 50%;
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 0 15px rgb(255, 255, 255 / 50%);
     margin-left: 0.5rem;
}
.play-btn2::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #0084ba;
    z-index: 100;
    transition: all .4s cubic-bezier(.55,.055,.675,.19);
}
}

/* footer*/
.menu-footer ul li a{
	 text-transform: none !important;
}
.menu-footer h5{
	 text-transform: none !important;
}
/* appels d'offres accueil */
.block-appels{
	height: 9.25rem !important;
}
.block-actu h3, .block-appels h3, .rounded-block h3{
	font-weight: 500 !important;
}
.block-actu h3.titre-appels, .block-appels h3.titre-appels, .rounded-block h3.titre-appels {
    font-size: 1.1rem;
}
.block-appels  a:hover {
    text-decoration: none;
}
.slick-slide:hover {
    text-decoration: none;
}
